/* eslint-disable indent */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useContext } from 'react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useHistory } from 'react-router-dom';
import { useTranslator, IntlContext } from '@jutro/locale';
import { CONSTANTS, WMICFeatureFlagUtil, WMICAmpUtil } from 'wmic-portals-utils-js';
import { WMICButton } from 'wmic-components-platform-react';
import { EndorsementService } from 'gw-capability-policychange';
import { WMICEndorsementUtil, WMICPolicyChangeStateService, WMICControllerUtil } from 'wmic-capability-policychange';
import DeleteVehicleModel from 'wmic-capability-policychange/models/DeleteVehicleModel';
import VehicleModel from 'wmic-capability-policychange/models/VehicleModel';

import { INITIAL_VIEW_STATE, VIEW_KEYS, STEPS, ANNUAL_MILEAGE_UNDER12000_TOGGLE, CAN_EDIT_EFFECTIVE_DATE, AGENT_ASSISTED_FLOW, MAX_DAYS_IN_PAST, 
    MESSAGE_TYPES, POLICY_ATTACHMENTS, STATE_KEYS, COMMON_ANNUAL_MILEAGE, VEHICLE_KEYS  } from './WMICRemoveVehicleWizardConsts';
import { getVehicleName, getCurrentVehicleIndexByNumber, isLastRemainingVehicle, isFirstRemainingVehicle, getOccasionalDrivers, getDateAfterDays, setOccasionalDriversAssignVehicleError } from './WMICRemoveVehicleWizardUtils';
import {
    checkEffectiveDate,
    checkVehicleMileage,
    adjustVehicleUsing,
    checkVehiclesUsages,
    checkPrincipalDriversAssignment,
    checkPrincipalDriversIsNotEmpty,
    checkOccasionalPrimaryDrivers,
    getMessageContent,
    getAndUpdateInitialStepReasonSelectionError,
    setRemainingVehiclesDriverAssignmentError } from './WMICRemoveVehicleWizardSteps/index';
import messages from './WMICRemoveVehicleWizard.messages';

const CTRL_KEY = 'deleteVehicle';

const WMICRemoveVehicleWizardNavigation = (props) => {
    const { policy, match, modelState, modelStateDispatch, viewState, viewStateDispatch, errorMessage, policyTransactions, removeVehiclePAComponentSetState, tempStorage, ...otherProps } = props;
    const translator = useTranslator();
    const { authHeader, userInfo, authUserData } = useAuthentication();
    const history = useHistory();
    const ldFlags = WMICFeatureFlagUtil.useFlags(authUserData);
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const accountHolder = _.get(policy, 'currentPeriod.contacts')
    const intl = useContext(IntlContext);

    // check if policy has 'trailer', 'camper', 'motorhome'
    const isPolicyHasAttachments = useCallback(() => {
        const existingTypes = _.uniq(policy.currentPeriod.lobs.personalAuto.vehicleDTOs.map((v) => v.vehicleType_WMIC));
        return _.intersection(POLICY_ATTACHMENTS, existingTypes).length !== 0;
    }, [policy]);

    const transformVehicleToModel = useCallback((vehicle) => {
        let primaryDriver = _.find(modelState[STATE_KEYS.DRIVERS_TO_ASSIGN], (vd) => vd.code === vehicle.principalDriver);
        let vehicleOccasionalDriversNames = [];
        if (AGENT_ASSISTED_FLOW.includes(modelState[STATE_KEYS.REASON])) {
            const vehicleDrivers = policy?.currentPeriod?.lobs?.personalAuto?.vehicleDTOs?.find((vo) => vo.vehicleNumber === vehicle.vehicleNumber)?.drivers_WMIC;
            if (primaryDriver === undefined) {
                primaryDriver = {};
                primaryDriver.name = vehicleDrivers?.find((d) => d?.driverType === 'principal')?.displayName?.toString();
            }
            const occasionalDrivers = vehicleDrivers?.filter((d) => d?.driverType === 'occasional');
            occasionalDrivers.map(d => {
                vehicleOccasionalDriversNames.push(d?.displayName);
            });
        } else {
            vehicleOccasionalDriversNames = _.reduce(modelState[STATE_KEYS.OCCASIONAL_DRIVERS], (acc, o) => {o.vehicleId === vehicle.fixedId.toString() && acc.push(o.displayName); return acc}, []);
        }
        
        return new VehicleModel({
            displayName: getVehicleName(vehicle),
            year: vehicle.year,
            make: vehicle.make,
            model: vehicle.model,
            vin: vehicle.vin,
            primaryUse: vehicle.primaryUse,
            annualMileage: vehicle.annualMileage,
            annualMileageUnder12000Toggle: vehicle.annualMileageUnder12000Toggle ?? (vehicle.annualMileage >= COMMON_ANNUAL_MILEAGE ? ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_DISPLAY_NON : ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_UNKNOWN),
            fixedId: vehicle.fixedId,
            vehicleType: vehicle.vehicleType,
            camperAttached: vehicle.camperAttached,
            shell: vehicle.shell,
            vehicleNumber: vehicle.vehicleNumber,
            primaryDriver: primaryDriver?.code,
            occasionalDrivers: vehicle.occasionalDrivers,
            primaryDriverName: primaryDriver?.name,
            occasionalDriversNames: vehicleOccasionalDriversNames.length > 0 ?  vehicleOccasionalDriversNames?.join(', ') : translator(messages.noneMessage)
        });
    }, [modelState, policy]);

    const saveChangeRequestToStorage = useCallback(() => {
        const id = 'delete-vehicle';
        const model = {
            vehicleNumber: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].vehicleNumber,
            changeType: 'delete-vehicle',
            objectType: 'DeleteVehicleModel',
            changeModel: new DeleteVehicleModel({
                reason: modelState[STATE_KEYS.REASON],
                date: modelState[STATE_KEYS.EFFECTIVE_DATE],
                vehicle: new VehicleModel({
                    year: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].year,
                    make: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].make,
                    model: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].model,
                    vin: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].vin,
                    primaryUse: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].primaryUse,
                    annualMileage: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].annualMileage,
                    fixedId: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].fixedId,
                    vehicleType: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].vehicleType,
                    camperAttached: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].camperAttached,
                    shell: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].shell,
                    vehicleNumber: modelState[STATE_KEYS.VEHICLE_TO_REMOVE].vehicleNumber,
                    displayName: getVehicleName(modelState[STATE_KEYS.VEHICLE_TO_REMOVE]),
                }),
                remainingVehicles: modelState[STATE_KEYS.REMAINING_VEHICLES].map((v) => transformVehicleToModel(v)),
                originalVehicles: modelState[STATE_KEYS.ORIGINAL_VEHICLES].map((v) => transformVehicleToModel(v)),
                occasionalDrivers: modelState[STATE_KEYS.OCCASIONAL_DRIVERS],
                jobId: modelState[STATE_KEYS.JOB_ID],
                status: modelState[STATE_KEYS.STATUS]
            })
        };

        WMICPolicyChangeStateService.addChangeRequest(CTRL_KEY, id, model);
    }, [modelState, transformVehicleToModel]);

    const showDefaultError = useCallback((specificError) => {
        errorMessage.current = getMessageContent(specificError || 'defaultError', translator);
        viewStateDispatch({ value: {
                [VIEW_KEYS.STEP]: STEPS.ERROR,
                [VIEW_KEYS.PREPARING_QUOTE]: false,
                [VIEW_KEYS.IS_LOADING]: false,
            }});

    }, [errorMessage, translator, viewStateDispatch]);

    const handleAssistedFlow = useCallback(() => {
        viewStateDispatch({ type: VIEW_KEYS.IS_LOADING, value: true });
        ldFlags.then((flags) => {
            const availability = WMICFeatureFlagUtil.queryAvailabilityAMP(flags, featureFlags.ASSISTEDPOLICYCHANGE);
            if (availability?.isAvailable) {
                WMICEndorsementUtil.notTaken(modelState[STATE_KEYS.JOB_ID], authHeader);
                saveChangeRequestToStorage();
                WMICControllerUtil.handleSubmit({
                    history: history,
                    policyNumber: policyNumber,
                    termNumber: termNumber,
                    policyData: policy,
                    policyType: policyType,
                    accountInfo: accountHolder,
                    authHeader: authHeader,
                    userInfo: userInfo, 
                    authUserData: authUserData
                }, translator);
            } else {
                showDefaultError();
            }
        }).catch(() => {
            showDefaultError();
        })

    }, [accountHolder, authHeader, featureFlags.ASSISTEDPOLICYCHANGE, history, ldFlags, modelState, policy, policyNumber, policyType, saveChangeRequestToStorage, showDefaultError, termNumber, translator, viewStateDispatch]);

    const handleSave = useCallback(() => {
        if (!modelState[STATE_KEYS.VEHICLE_TO_REMOVE]) {
            showDefaultError();
        }

        viewStateDispatch({ value: {
            [VIEW_KEYS.PREPARING_QUOTE]: true,
            [VIEW_KEYS.HAS_CLICKED_CONTINUE]: false,
        }});

        const vehiclesAssignment = [];
        // reassign drivers
        _.forEach(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => {
            const occasionalDrivers = _.reduce(
                modelState[STATE_KEYS.OCCASIONAL_DRIVERS], (acc, o) => 
                    {
                        o.vehicleId === v.fixedId.toString() && acc.push(o.fixedId); return acc
                    }, 
                []);
            vehiclesAssignment.push({
                publicID: v.publicId,
                vehicleID: v.fixedId,
                primaryDriverID: v.principalDriver,
                occasionalDriversID: occasionalDrivers,
                primaryUse: v.primaryUse,
                annualMileage: v.annualMileage.toString()
            });
        });

        saveChangeRequestToStorage();

        const description = `Vehicle ${getVehicleName(modelState[STATE_KEYS.VEHICLE_TO_REMOVE])} was deleted. Reason: ${modelState[STATE_KEYS.REASON]}`;
        const effectiveDate = CAN_EDIT_EFFECTIVE_DATE.includes(modelState[STATE_KEYS.REASON]) 
            ? getDateAfterDays(modelState[STATE_KEYS.EFFECTIVE_DATE], 1)
            : modelState[STATE_KEYS.EFFECTIVE_DATE]

        EndorsementService.deleteVehicle_WMIC(
            modelState[STATE_KEYS.JOB_ID],
            effectiveDate,
            description,
            modelState[STATE_KEYS.VEHICLE_TO_REMOVE].fixedId,
            vehiclesAssignment,
            authHeader
        ).then((result) => {
            if (result.exceptions && result.exceptions.length > 0) {
                showDefaultError();
            } else {
                EndorsementService.quoteWithDaysEndorsement_WMIC(
                    modelState[STATE_KEYS.JOB_ID],
                    MAX_DAYS_IN_PAST,
                    CONSTANTS.POLICY_CHANGE_TYPE.SELF_SERVE_REMOVE_VEHICLE,
                    authHeader
                ).then((policyChange) => {
                    modelState[STATE_KEYS.STATUS] = policyChange?.status;
                    saveChangeRequestToStorage();

                    if (policyChange?.exceptions && policyChange?.exceptions.length > 0) {
                        const hasSpecificError = _.some(policyChange?.exceptions, 
                            (ex) => _.includes(ex.message, '602-GW_UNDERWRITING_ERROR') || _.includes(ex.message, 'PAInsuredOrSpouseRegOwnerForVehicle'));
                        showDefaultError(hasSpecificError ? 'PAInsuredOrSpouseRegOwnerForVehicle' : null);
                    } else {
                        history.push(`/review-policy-change/${policyNumber}`);
                        viewStateDispatch({ type: VIEW_KEYS.PREPARING_QUOTE, value: false })
                    }
                }).catch(() => {
                    showDefaultError();
                })
        }
        }).catch(() => {
            showDefaultError();
        })
    }, [authHeader, history, modelState, policyNumber, saveChangeRequestToStorage, showDefaultError, viewStateDispatch]);

    const getNextStep = useCallback(() => {
        let hasClickedContinue = false;

        switch (viewState.step) {
            case STEPS.INITIAL: {

                hasClickedContinue = true;
                viewStateDispatch({type:VIEW_KEYS.HAS_CLICKED_CONTINUE, value: hasClickedContinue});
                
                const hasError = getAndUpdateInitialStepReasonSelectionError(modelState, modelStateDispatch, hasClickedContinue);

                if (hasError === true ) {
                    viewStateDispatch({type: VIEW_KEYS.STEP, value: STEPS.INITIAL});
                } else {
                    WMICAmpUtil.scrollToTop();

                    // can change effective date or one vehicle in policy
                    if (CAN_EDIT_EFFECTIVE_DATE.includes(modelState[STATE_KEYS.REASON]) || modelState[STATE_KEYS.REMAINING_VEHICLES].length === 0) {
                        viewStateDispatch({type: VIEW_KEYS.STEP, value: STEPS.EFFECTIVE_DATE});
                    } else
                    // need Agent Assisted Flow
                    if (AGENT_ASSISTED_FLOW.includes(modelState[STATE_KEYS.REASON]) || isPolicyHasAttachments()) {
                        handleAssistedFlow();
                    } else {
                        adjustVehicleUsing(modelState, modelStateDispatch);
                        viewStateDispatch({type: VIEW_KEYS.STEP, value: STEPS.VEHICLE_USING});
                    }
                }

                break;
            }
            case STEPS.EFFECTIVE_DATE: {
                const checkEffectiveDateResult = checkEffectiveDate(modelState, policy, policyTransactions);

                if (checkEffectiveDateResult && checkEffectiveDateResult.summary) {
                    errorMessage.current = getMessageContent(MESSAGE_TYPES.EFFECTIVE_DATE, translator, intl, modelState, policy, viewStateDispatch, checkEffectiveDateResult);
                    viewStateDispatch({type: VIEW_KEYS.STEP, value: STEPS.ERROR});
                } else {
                    WMICAmpUtil.scrollToTop();
                    // one vehicle in policy
                    if (modelState[STATE_KEYS.REMAINING_VEHICLES].length === 0 || checkEffectiveDateResult.hasFutureTransaction || isPolicyHasAttachments()) {
                        handleAssistedFlow();
                    } else {
                        adjustVehicleUsing(modelState, modelStateDispatch);
                        viewStateDispatch({type: VIEW_KEYS.STEP, value:  STEPS.VEHICLE_USING});
                    }
                }

                break;
            }
            case STEPS.VEHICLE_USING: {
                //enforced the required toggle selection for vehicle annualmileage less than 12000 miles
                const workingVehicle = (modelState[STATE_KEYS.REMAINING_VEHICLES].find((v) => v.vehicleNumber === viewState.currentVehicleNumber));
                if (workingVehicle?.annualMileageUnder12000Toggle === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_UNKNOWN || 
                    workingVehicle?.annualMileageUnder12000Toggle === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_REMAIN_UNKNOWN) {
                    workingVehicle.annualMileageUnder12000Toggle = ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_REMAIN_UNKNOWN;
                    viewStateDispatch({type: VIEW_KEYS.CURRENT_VEHICLE_NUMBER, value: viewState.currentVehicleNumber});
                }
                else {
                    WMICAmpUtil.scrollToTop();

                    if (workingVehicle?.annualMileageUnder12000Toggle === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_YES) {
                        workingVehicle.annualMileage = COMMON_ANNUAL_MILEAGE; //update annual mileage.
                    }
                    const checkResult = checkVehicleMileage(modelState, viewState.currentVehicleNumber);
                    if (checkResult.isMileageReduced) {
                        errorMessage.current =  getMessageContent(MESSAGE_TYPES.VEHICLE_MILEAGE_REDUCED, translator, intl, modelState, policy, viewStateDispatch, checkResult);
                        viewStateDispatch({type: VIEW_KEYS.STEP, value:  STEPS.ERROR});
                    } else if (!isLastRemainingVehicle(viewState.currentVehicleNumber, modelState[STATE_KEYS.REMAINING_VEHICLES]) && viewState.prevStep !== STEPS.ERROR) {
                            // not last vehicle
                            const nextVehicleIndex = getCurrentVehicleIndexByNumber(viewState.currentVehicleNumber, modelState[STATE_KEYS.REMAINING_VEHICLES]) + 1;
                            viewStateDispatch({type: VIEW_KEYS.CURRENT_VEHICLE_NUMBER, value: modelState[STATE_KEYS.REMAINING_VEHICLES][nextVehicleIndex]?.vehicleNumber});
                    } else {
                        // last vehicle
                        const vehicleUsingCheckResult = checkVehiclesUsages(modelState);
                        if (vehicleUsingCheckResult && !vehicleUsingCheckResult.summary) {
                            errorMessage.current = getMessageContent(MESSAGE_TYPES.VEHICLE_USING_REVIEW, translator, intl, modelState, policy, viewStateDispatch, vehicleUsingCheckResult);
                            viewStateDispatch({type: VIEW_KEYS.STEP, value:  STEPS.ERROR});
                        } else {
                            modelState[STATE_KEYS.DRIVERS_TO_ASSIGN].length > 1
                                ? viewStateDispatch({type: VIEW_KEYS.STEP, value: STEPS.ASSIGN_PRIMARY_DRIVERS})
                                : handleSave();
                        }
                    }
                }

                break;
            }
            case STEPS.ASSIGN_PRIMARY_DRIVERS: {                
                setRemainingVehiclesDriverAssignmentError(modelState, viewStateDispatch, true);
                const checkResult = checkPrincipalDriversAssignment(modelState);
                
                if ((modelState[STATE_KEYS.REMAINING_VEHICLES].filter(v => v[VEHICLE_KEYS.ASSIGN_DRIVER_ERROR]).length > 0) ||
                    (!checkPrincipalDriversIsNotEmpty(modelState) && 
                        (!checkResult.isPrimaryDriversAssignmentCorrect && (checkResult.isAtleastOneVehicleAssignedtoDriver === null || !checkResult.isAtleastOneVehicleAssignedtoDriver))
                        || 
                        (checkResult.isPrimaryDriversAssignmentCorrect && checkResult.isAtleastOneVehicleAssignedtoDriver !== null && !checkResult.isAtleastOneVehicleAssignedtoDriver)
                    )
                   ) {
                    viewStateDispatch({type: VIEW_KEYS.STEP, value: STEPS.ASSIGN_PRIMARY_DRIVERS});
                } else {
                    const occasionalDrivers = getOccasionalDrivers(modelState, policy.currentPeriod.lobs.personalAuto.driverDTOs);

                    WMICAmpUtil.scrollToTop();

                    modelStateDispatch({ type: STATE_KEYS.OCCASIONAL_DRIVERS, value: occasionalDrivers });

                    if (occasionalDrivers.length > 0) {
                        viewStateDispatch({ value: {
                            [VIEW_KEYS.STEP]: STEPS.ASSIGN_OCCASIONAL_DRIVERS,
                            [VIEW_KEYS.HAS_CLICKED_CONTINUE]: false,
                        }});
                    } else {
                        handleSave();
                    }
                }
                
                break;
            }
            case STEPS.ASSIGN_OCCASIONAL_DRIVERS: {
                hasClickedContinue = true;
                
                viewStateDispatch({type:VIEW_KEYS.HAS_CLICKED_CONTINUE, value: hasClickedContinue});
                setOccasionalDriversAssignVehicleError(hasClickedContinue, modelState);

                if (modelState[STATE_KEYS.OCCASIONAL_DRIVERS].length === 0 || modelState[STATE_KEYS.OCCASIONAL_DRIVERS].filter(v => !v.vehicleId).length > 0) {
                    viewStateDispatch({type: VIEW_KEYS.STEP, value: STEPS.ASSIGN_OCCASIONAL_DRIVERS});
                } else {
                    WMICAmpUtil.scrollToTop();

                    handleSave();
                }
                
                break;
            }
            default: {
                viewStateDispatch(INITIAL_VIEW_STATE);
            }
        }

    }, [errorMessage, handleAssistedFlow, handleSave, isPolicyHasAttachments, modelState, modelStateDispatch, policy, policyTransactions, translator, viewState.currentVehicleNumber, viewState.prevStep, viewState.step, viewStateDispatch]);

    const getPrevStep = useCallback(() => {
        switch (viewState.step) {
            case STEPS.INITIAL: {
                WMICPolicyChangeStateService.addState({ editDeleteVehicle: { step: STEPS.PICK_VEHICLE, 
                                                                             jobId: modelState[STATE_KEYS.JOB_ID], 
                                                                             vehicleNumber: viewState.currentVehicleNumber }});
                saveChangeRequestToStorage();
                removeVehiclePAComponentSetState({viewMode: 1});
                break;
            }
            case STEPS.EFFECTIVE_DATE: {
                viewStateDispatch({type: VIEW_KEYS.STEP, value:  STEPS.INITIAL});
                break;
            }
            case STEPS.VEHICLE_USING: {
                //reset vehicle under 12000 miles toggle value to reset the 'VEHICLE_USING' display.
                const workingVehicle = (modelState[STATE_KEYS.REMAINING_VEHICLES].find((v) => v.vehicleNumber === viewState.currentVehicleNumber));
                if (workingVehicle?.annualMileageUnder12000Toggle ===  ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_REMAIN_UNKNOWN) {
                    workingVehicle.annualMileageUnder12000Toggle = ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_UNKNOWN;
                }
                else if (workingVehicle?.annualMileageUnder12000Toggle === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_YES) {
                    workingVehicle.annualMileage = COMMON_ANNUAL_MILEAGE; //update annual mileage to match toggle Yes.
                }
                if (isFirstRemainingVehicle(viewState.currentVehicleNumber, modelState[STATE_KEYS.REMAINING_VEHICLES])) {
                    viewStateDispatch({type: VIEW_KEYS.STEP, value: CAN_EDIT_EFFECTIVE_DATE.includes(modelState[STATE_KEYS.REASON]) ? STEPS.EFFECTIVE_DATE : STEPS.INITIAL});
                } else {
                    // not first vehicle
                    const nextVehicleIndex = getCurrentVehicleIndexByNumber(viewState.currentVehicleNumber, modelState[STATE_KEYS.REMAINING_VEHICLES]) - 1;
                    viewStateDispatch({type: VIEW_KEYS.CURRENT_VEHICLE_NUMBER, value:  modelState[STATE_KEYS.REMAINING_VEHICLES][nextVehicleIndex].vehicleNumber});
                }

                break;
            }
            case STEPS.ASSIGN_PRIMARY_DRIVERS: {
                setRemainingVehiclesDriverAssignmentError(modelState, viewStateDispatch, undefined);
                viewStateDispatch({type: VIEW_KEYS.STEP, value: STEPS.VEHICLE_USING});
                break;
            }
            case STEPS.ASSIGN_OCCASIONAL_DRIVERS: {
                setOccasionalDriversAssignVehicleError(undefined, modelState);
                viewStateDispatch({type: VIEW_KEYS.STEP, value: STEPS.ASSIGN_PRIMARY_DRIVERS});
                break;
            }
            default: {
                viewStateDispatch(INITIAL_VIEW_STATE);
            }
        }
    }, [modelState, viewState.currentVehicleNumber, viewState.step, viewStateDispatch]);

    const isNextDisable = useCallback(() => {
        if (viewState.step === STEPS.EFFECTIVE_DATE) {
            return _.isEmpty(modelState[STATE_KEYS.EFFECTIVE_DATE]);
        }

        if (viewState.step === STEPS.VEHICLE_USING) {
            const vehicle = modelState[STATE_KEYS.REMAINING_VEHICLES].find((v) => v.vehicleNumber === viewState.currentVehicleNumber);
            return !(!!vehicle.primaryUse && vehicle.annualMileage > 0 && vehicle.annualMileage <= 99999);
        }

        return false;
    }, [modelState, viewState.currentVehicleNumber, viewState.step]);

    const isBackBtnVisible = useCallback(() => {
        switch (viewState.step) {
            case STEPS.INITIAL: {
                return modelState[STATE_KEYS.STATUS]?.toLowerCase() !== CONSTANTS.QUOTED.toLowerCase()
            }
            default:
                return true;
        }

    }, [modelState, viewState.step]);

    const handleCancelForm = useCallback(() => {
        if ( modelState[STATE_KEYS.JOB_ID]) {
            WMICEndorsementUtil.notTaken(modelState[STATE_KEYS.JOB_ID], authHeader);
        }
        tempStorage.pop(CONSTANTS.PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER);
        history.push(`/account-policy-change/${policyNumber}/${termNumber}/auto/${CONSTANTS.POLICY_CHANGE_TYPE.SELECT_TYPE}`);
    }, [authHeader, history, modelState, policyNumber, termNumber]);

    return (
        <div {...otherProps}>
            <WMICButton type="outlined" onClick={handleCancelForm} aria-label={translator(messages.cancelAriaMessage)}>
                {translator(messages.cancel)}
            </WMICButton>
            {viewState.step === STEPS.ERROR && errorMessage.current && errorMessage.current.handleBack && isBackBtnVisible() &&
                <WMICButton type="primary" onClick={errorMessage.current.handleBack} aria-label={translator(messages.backAriaMessage)}>
                    {translator(messages.back)}
                </WMICButton>
            }
            {viewState.step !== STEPS.ERROR &&
                <Fragment>
                    { isBackBtnVisible() &&
                        <WMICButton type="primary" onClick={getPrevStep} aria-label={translator(messages.backAriaMessage)}>
                            {translator(messages.back)}
                        </WMICButton>
                    }
                    <WMICButton type="primary" onClick={getNextStep} disabled={isNextDisable()}>
                        {translator(messages.continue)}
                    </WMICButton>
                </Fragment>
            }
        </div>
    );
}

WMICRemoveVehicleWizardNavigation.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    modelStateDispatch: PropTypes.func.isRequired
};

export default WMICRemoveVehicleWizardNavigation;
